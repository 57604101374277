import _ from "lodash";
import { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
/* Components */
import { QuadAttribute, PrivacyConsentScopeParams } from "@quadrata/client-react";
import { QuadrataReact } from "@quadrata/quadrata-react";
import { Modal, Button } from "react-bootstrap";
import PassportModal from "./PassportModal";
import WelcomeModal from "./WelcomeModal";
import SignInModal from "./SignInModal";
import Loader from "react-js-loader";
import KYCAlerts from "./KYCAlerts";
import Web3 from "web3";
/* i18n */
import { connect, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
/* Store */
import { selectAPICall, selectAPICallMultiple } from "@ensuro/api-calls-store/src/store/api/selectors";
import { selectLastTransact, selectSign } from "@ensuro/ethereum-store/src/store/ethereum/selectors";
import { selectUserAddress } from "../../store/user/selectors";
/* Helpers */
import { refreshAPICalls } from "../../helpers/api_store_helper";
import { chain, quadrata } from "../../config";
import "@quadrata/core-react/lib/cjs/quadrata-ui.min.css";
import "./style.scss";

// Required Attributes
const requiredAttributes = quadrata.requiredAttributes.map((attr) => QuadAttribute[attr]);
// Required Private Permissions
const requiredPrivacyScopes = quadrata.privateData.map((p) => PrivacyConsentScopeParams[p]);

const makeAPICalls = (userAddress, accessToken) => {
  const attributes = requiredAttributes.map((attr) => attr.toLowerCase()).join(",");
  const privacyScopes = requiredPrivacyScopes.join(",");
  return [
    { apiName: "checkUser", args: [userAddress], forceCall: true },
    {
      apiName: "quadAttributes",
      args: [userAddress, chain.id, attributes, privacyScopes],
      headers: { Authorization: `Bearer ${accessToken}`, "Content-Type": "application/json" },
      forceCall: true,
    },
  ];
};

const StepSpinner = ({ onHide, show }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Loader type="spinner-default" height={50} width={100} />
    </Modal>
  );
};

const StepQuadrata = ({ configShared, configUser, setQuadModal, mintPassport, signMessage }) => {
  return (
    <QuadrataReact
      configBusiness={configShared}
      configUser={configUser}
      onHide={() => setQuadModal(false)}
      onMintClick={mintPassport}
      onSign={signMessage}
    />
  );
};

const StepWelcome = ({ show, onHide, setQuadModal }) => {
  return <WelcomeModal show={show} onHide={onHide} openQuad={() => setQuadModal(true)} />;
};

const StepPassport = ({ show, onHide, showSignInModal, quadrata }) => {
  return <PassportModal show={show} onHide={onHide} onClick={showSignInModal} tokenId={quadrata.tokenId} />;
};

const StepWhitelisted = ({ show, onHide, t, state, openDeposit }) => {
  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t("KYC")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <KYCAlerts state={state} />
      </Modal.Body>
      {state === "WHITELISTED" && (
        <Modal.Footer>
          <Button variant="primary" onClick={openDeposit}>
            {t("Continue")}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

const getCurrentStep = (accessToken, attributesToClaim, privDataToAllow, quadModal, state) => {
  if (accessToken === undefined || attributesToClaim === undefined || privDataToAllow === undefined) return StepSpinner;
  if (!(_.isEmpty(attributesToClaim) && _.isEmpty(privDataToAllow))) {
    return quadModal ? StepQuadrata : StepWelcome;
  }
  if (state === "SIGN_IN") return StepPassport;
  return StepWhitelisted;
};

const QuadrataModal = ({
  t,
  userAddress,
  show,
  onHide,
  onContinue,
  checkUser,
  accessToken,
  welcomeSignature,
  privDataSignature,
  txHash,
  completed,
  passportData,
  attributesToClaim,
  privDataToAllow,
}) => {
  let dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [quadModal, setQuadModal] = useState(false);
  const verifyUser = useRef(false);

  let configShared = {
    accessToken: accessToken,
    apiUrl: `${quadrata.apiUrl}/v1`,
    environment: quadrata.environment,
    protocolName: "Ensuro",
    showSocialButtons: false,
  };

  let configUser = {
    ...configShared,
    account: userAddress || "",
    attributes: attributesToClaim,
    chainId: chain.id,
    mintComplete: completed,
    privacyScopes: privDataToAllow,
    signature: welcomeSignature,
    signatureConsent: privDataSignature,
    transactionHash: txHash,
  };

  useEffect(() => {
    if (!accessToken && show) {
      dispatch({ type: "API_CALL", apiName: "accessToken", args: [], method: "POST" });
    } else if (userAddress && accessToken && show) {
      return refreshAPICalls(dispatch, makeAPICalls(userAddress, accessToken), 1000);
    }
  }, [show, accessToken, dispatch, userAddress]);

  const signMessage = async (message, isConsent) => {
    if (isConsent) {
      dispatch({ type: "ETH_PLAIN_SIGN", key: "privateData", message: message, userAddress: userAddress });
    } else {
      dispatch({ type: "ETH_PLAIN_SIGN", key: "quadrataWelcome", message: message, userAddress: userAddress });
    }
  };

  const openDeposit = () => {
    onHide();
    onContinue();
  };

  const mintPassport = (mintParams) => {
    dispatch({
      type: "ETH_TRANSACT",
      address: quadrata.passportAddress,
      abi: "QuadrataPassport",
      method: "setAttributesIssuer",
      args: [mintParams.account, mintParams.params[0], mintParams.signaturesIssuers[0]],
      // method: "setAttributesBulk",
      // args: [mintParams.params, mintParams.signaturesIssuers, mintParams.signatures],
    });
  };

  const showSignInModal = () => {
    onHide();
    setShowModal(true);
  };

  const getKYCState = () => {
    if (!checkUser || (checkUser && !checkUser.value)) return "SIGN_IN";
    return {
      kyc_pending: "PENDING",
      kyc_verified: "VERIFIED",
      kyc_approved: "APPROVED",
      kyc_failed: "FAILED",
      whitelist: "WHITELISTED",
    }[checkUser.value.status];
  };

  let state = getKYCState();

  useEffect(() => {
    if (
      !verifyUser.current &&
      show &&
      attributesToClaim &&
      !attributesToClaim.length &&
      privDataToAllow &&
      !privDataToAllow.length &&
      (state === "PENDING" || state === "APPROVED")
    ) {
      dispatch({ type: "API_CALL", apiName: "refreshWallet", args: [userAddress], method: "POST" });
      verifyUser.current = true; // call only once
    }
  }, [show, attributesToClaim, privDataToAllow, state, userAddress, dispatch]);

  const CurrentStep = getCurrentStep(accessToken, attributesToClaim, privDataToAllow, quadModal, state);

  return (
    <>
      <CurrentStep
        show={show}
        onHide={onHide}
        configShared={configShared}
        configUser={configUser}
        setQuadModal={setQuadModal}
        mintPassport={mintPassport}
        signMessage={signMessage}
        showSignInModal={showSignInModal}
        quadrata={quadrata}
        openDeposit={openDeposit}
        t={t}
      />
      <SignInModal show={showModal} onHide={() => setShowModal(false)} state={state} passport={passportData} />
    </>
  );
};

QuadrataModal.propTypes = {
  t: PropTypes.any,
};

const mapStateToProps = (state) => {
  let userAddress = selectUserAddress(state.UserReducer);
  userAddress = userAddress ? Web3.utils.toChecksumAddress(userAddress) : undefined;
  const accessToken = selectAPICall(state.APIReducer, "accessToken", [userAddress]);

  const lastTx = selectLastTransact(state.EthereumReducer);
  const txHash = lastTx && lastTx.state === "MINED" ? lastTx.txHash : undefined;
  const completed = lastTx && lastTx.state === "MINED" ? true : false;

  const sign = selectSign(state.EthereumReducer, "quadrataWelcome", userAddress);
  const welcomeSignature = sign && sign.state === "SIGNED" ? sign.signature : undefined;

  const privDataSign = selectSign(state.EthereumReducer, "privateData", userAddress);
  const privDataSignature = privDataSign && privDataSign.state === "SIGNED" ? privDataSign.signature : undefined;

  const [checkUser, passportData] = !accessToken
    ? [{}, []]
    : selectAPICallMultiple(state.APIReducer, makeAPICalls(userAddress, accessToken));

  const data = passportData?.value;
  const attributesToClaim =
    data?.onboardStatus &&
    Object.keys(data.onboardStatus).filter((key) => {
      const value = data.onboardStatus[key];
      return (!value.mintedOnchain || value.status !== "READY") && key !== "IS_BUSINESS";
    });

  let privDataToAllow =
    data?.privacyStatus &&
    Object.keys(data.privacyStatus).filter((key) => {
      return data.privacyStatus[key].status !== "ALLOWED";
    });
  if (quadrata.skipPrivateData.includes(userAddress)) {
    console.info("Skipping private data request for ", userAddress);
    privDataToAllow = [];
  }

  return {
    userAddress,
    accessToken,
    checkUser,
    welcomeSignature,
    privDataSignature,
    txHash,
    completed,
    passportData,
    attributesToClaim,
    privDataToAllow,
  };
};

export default connect(mapStateToProps)(withTranslation()(QuadrataModal));
