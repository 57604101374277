import React from "react";
import PropTypes from "prop-types";
import Parameter from "../CommonForBoth/Parameter";
import { Card } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { etherscanLink } from "../../helpers/ensuro_helper";
import colors from "../../colors";
import "./risk-style.scss";

const RisksParams = ({
  t,
  address,
  rmParams,
  jrEtk,
  srEtk,
  maxScrPerPolicy,
  maxDuration,
  scrLimit,
  paInRM,
  activeExposure,
}) => {
  return (
    <Card fluid className="params">
      <a target="_blank" className="right-aligned" rel="noopener noreferrer" href={etherscanLink(address)}>
        <p className="font-size-14">
          {t("ViewContractInBlockExplorer")} <i className="uil uil-external-link-alt" />
        </p>
      </a>
      <br />
      <Parameter
        name={t("Jr Collateralization Ratio")}
        value={rmParams?.jrCollRatio}
        suffix={"%"}
        percentage={true}
        options={{ maximumFractionDigits: 2, notation: "standard" }}
      />
      <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />

      <Parameter
        name={t("Sr Collateralization Ratio")}
        value={rmParams?.srCollRatio}
        suffix={"%"}
        percentage={true}
        options={{ maximumFractionDigits: 2, notation: "standard" }}
      />
      <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />

      <Parameter name={t("MoC")} value={rmParams?.moc} options={{ maximumFractionDigits: 2, notation: "standard" }} />
      <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />

      <Parameter
        name={t("Ensuro Fee")}
        value={rmParams?.ensuroFee}
        suffix={"%"}
        percentage={true}
        options={{ maximumFractionDigits: 2, notation: "standard" }}
      />
      <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />

      <Parameter
        name={t("Ensuro CoC Fee")}
        value={rmParams?.ensuroCoCFee}
        suffix={"%"}
        percentage={true}
        options={{ maximumFractionDigits: 2, notation: "standard" }}
      />
      <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />

      <Parameter
        name={t("JR RoC")}
        value={rmParams?.jrRoc}
        suffix={"%"}
        percentage={true}
        options={{ maximumFractionDigits: 2, notation: "standard" }}
      />
      <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />

      <Parameter
        name={t("SR RoC")}
        value={rmParams?.srRoc}
        suffix={"%"}
        percentage={true}
        options={{ maximumFractionDigits: 2, notation: "standard" }}
      />
      <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />

      <Parameter
        name={t("Max Payout per Policy")}
        value={maxScrPerPolicy}
        options={{ maximumFractionDigits: 2, notation: "standard" }}
      />
      <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />

      <Parameter name={t("Max Duration")} value={maxDuration} suffix={" days"} />

      <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />
      <Parameter
        name={t("Exposure")}
        value={{ current: activeExposure, limit: scrLimit }}
        renderType={"comparison"}
        options={{ maximumFractionDigits: 2, notation: "compact" }}
      />

      <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />
      <Parameter
        name={t("Portfolio")}
        value={{ state: paInRM, link: { linkedTo: `/portfolios/${paInRM.value?.address}`, str: paInRM.value?.name } }}
        renderType={"link"}
        options={{ maximumFractionDigits: 2, notation: "compact" }}
      />

      <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />
      <Parameter
        name={t("JR Pool")}
        value={{ state: jrEtk, link: { linkedTo: `/etokens/${jrEtk.value?.address}`, str: jrEtk.value?.name } }}
        renderType={"link"}
        options={{ maximumFractionDigits: 2, notation: "compact" }}
      />

      <hr style={{ width: "90%", color: colors.secondary, marginTop: "0" }} />
      <Parameter
        name={t("SR Pool")}
        value={{ state: srEtk, link: { linkedTo: `/etokens/${srEtk.value?.address}`, str: srEtk.value?.name } }}
        renderType={"link"}
        options={{ maximumFractionDigits: 2, notation: "compact" }}
      />

      <br />
    </Card>
  );
};

RisksParams.propTypes = {
  t: PropTypes.any,
};

export default withTranslation()(RisksParams);
